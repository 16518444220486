import React, { useState } from 'react'
import AppContext from './AppContext'

const AppProvider = ({ children }) => {
  // App-level state
  const [appState, setAppState] = useState({
    isLoading: true,
    selectedPersonas: [],
    reportData: null,
    // location: null,
    // locationsList: [],
  })

  // Functions to update the state
  const setIsLoading = isLoading => {
    setAppState(prevState => ({ ...prevState, isLoading: isLoading }))
  }

  const setSelectedPersonas = selectedPersonas => {
    setAppState(prevState => ({ ...prevState, selectedPersonas: selectedPersonas }))
  }

  // const setLocation = (location) => {
  //   setAppState(prevState => ({ ...prevState,
  //     location: location }));
  // }

  const setReportData = reportData => {
    setAppState(prevState => ({ ...prevState, reportData: reportData }))
  }

  // const setLocationsList = (locationsList) => {
  //   setAppState(prevState => ({ ...prevState,
  //     locationsList: locationsList }));
  // }

  const [location, setLocation] = useState(null)
  const [userData, setUserData] = useState(null)
  const [locationsList, setLocationsList] = useState([])
  const [showCensus, setShowCensus] = useState(false)

  const [allPersonas, setAllPersonas] = useState([])

  const [selectedSponsor, setSelectedSponsor] = useState(null)
  const [sponsorsList, setSponsorsList] = useState([])

  const [selectedAffinityType, setSelectedAffinityType] = useState(null)
  const [affinityTypesList, setAffinityTypesList] = useState([])

  // Provide the state and functions to the context
  const contextValue = {
    appState,
    setAppState,
    userData,
    setUserData,
    setIsLoading,
    allPersonas,
    setAllPersonas,
    setSelectedPersonas,
    setReportData,
    location,
    setLocation,
    locationsList,
    setLocationsList,
    sponsorsList,
    setSponsorsList,
    selectedSponsor,
    setSelectedSponsor,
    affinityTypesList,
    setAffinityTypesList,
    selectedAffinityType,
    setSelectedAffinityType,
    showCensus,
    setShowCensus,
  }

  return (
    <AppContext.Provider value={contextValue}>
      {children}
      {/* {appState.isLoading ? <div>Loading...</div> : children} */}
    </AppContext.Provider>
  )
}

export default AppProvider
