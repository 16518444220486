import React, { useState, useContext, useEffect, useLayoutEffect } from 'react'
import AppContext from 'src/contexts-global/AppContext'
import Map from 'src/components/map/Map'
import PdfButton from 'src/components/pdf-button/PdfButton'
import { Box, Card, CardContent, Grid, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import PersonIcon from '@mui/icons-material/Person';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import HomeIcon from '@mui/icons-material/Home';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AirportShuttleIcon from '@mui/icons-material/AirportShuttle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import WorkIcon from '@mui/icons-material/Work';
import HelpTooltip from 'src/components/common/tooltip-help'
import DataCard from 'src/components/common/data-display-card'
import MapCard from 'src/components/common/map-card'
import CardTitle from 'src/components/common/card-title-template'
import PageContainer from 'src/components/common/page-container'
import FlexColumnContainer from 'src/components/common/flex-column-container'
import FlexRowContainer from 'src/components/common/flex-row-container'
import DashboardToolbar from 'src/components/dashboard-toolbar/DashboardToolbar'
import ModularBarGraph from 'src/components/graphs-charts/modularBar'
import DistanceTravelledMap from 'src/components/distance-travelled/DistanceTravelledMap'
import dayjs from 'dayjs'
import {
  GetDistanceTravelledDefaultEndDate,
  GetDistanceTravelledMetrics,
  GetDistanceTravelledPersona,
  GetTrafficMetrics,
} from 'src/requests/api-requests'
import theme from 'src/themes'
import MetricCard from 'src/components/metric-card'
import CustomCard from 'src/components/common/custom-card'
import PersonaSelector from 'src/components/common/persona-selector'
import { Groups, Person } from '@mui/icons-material'
import { KM_TO_MILES } from 'src/utils/constants'
import ModularPieChart from 'src/components/graphs-charts/modularPie'
import { Doughnut } from 'react-chartjs-2'

const DistanceTravelledPage = () => {
  // Global context (for persona)

  const {
    appState,
    userData,
    isLoading,
    setIsLoading,
    locationsList,
    location,
    setLocation,
    setAllPersonas,
    setSelectedPersonas,
    showCensus,
  } = useContext(AppContext)

  // const personas = appState.selectedPersonas ?? 'overall'
  const [personas, setPersonas] = useState('overall')
  
  // Page state (for date & location)

  const [reportData, setReportData] = useState()
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)

  const [cityBarGraphData, setCityBarGraphData] = useState(null)
  const [postalCodeBarGraphData, setPostalCodeBarGraphData] = useState(null)
  const [summaryData, setSummaryData] = useState(null)
  const [ageBarGraphData, setAgeBarGraphData] = useState(null)
  const [incomeBarGraphData, setIncomeBarGraphData] = useState(null)
  const [educationGraphData, setEducationGraphData] = useState(null)
  const [commutingGraphData, setCommutingGraphData] = useState(null)
  const [personaAverageBarGraphData, setPersonaAverageBarGraphData] = useState(null)
  const [personaMedianBarGraphData, setPersonaMedianBarGraphData] = useState(null)

  const [personaTopCityData, setPersonaTopCityData] = useState(null)
  const [personaTopPostalCodeData, setPersonaTopPostalCodeData] = useState(null)


  useLayoutEffect(() => {
    setIsLoading(false)
    setPersonaAverageBarGraphData(null)
    setPersonaMedianBarGraphData(null)
    setPersonaTopCityData(null)
    setPersonaTopPostalCodeData(null)
    setReportData(null)
    setLocation(null)
    setSelectedPersonas([])
    setAllPersonas([])
    setStartDate(null)
    setEndDate(null)
  }, [])

  useEffect(()=> {
    setAgeBarGraphData({
      labels: ['0 to 9 years', '10 to 19 years', '20 to 29 years', '30 to 39 years', '40 to 49 years', '50 to 59 years', '60 to 69 years', '70 to 79 years', '80 to 89 years', 'over 90'],
      datasets: [
        {
          data: [10, 11.4, 14.2, 14.7, 13.4, 14.1, 11.2, 7, 3.3, 0.8],
          backgroundColor: ['#1d4880'],
          borderColor: [],
          // borderWidth: 1
        },
      ],
    })
    setIncomeBarGraphData({
      labels: ['Under $10,000', '$10,000 to $19,999', '$20,000 to $29,999', '$30,000 to $39,999', '$40,000 to $49,999', '$50,000 to $59,999', '$60,000 to $69,999', '$70,000 to $79,999', '$80,000 to $89,999', '$90,000 to $99,999', '$100,000 and over'],
      datasets: [
        {
          data: [9.8, 12.5, 16.1, 11.9, 9.7, 7.9, 6.3, 5.0, 4.1, 3.4, 13.3],
          backgroundColor: ['#1d4880'],
          borderColor: [],
          // borderWidth: 1
        },
      ],
    })

    let educationData = [
      {
        label: "No certificate, diploma or degree",
        value: 14.2,
        color: theme.color.midBlue,
        cutout: "50%",
      },
      {
        label: "High school diploma or equivalency",
        value: 24.9,
        color: theme.color.blue,
        cutout: "50%",
      },
      {
        label: "Postsecondary certificate, diploma or degree",
        value: 22.9,
        color: theme.color.turquoise,
        cutout: "50%",
      },
      {
        label: "Bachelor's degree or higher	",
        value: 38.0,
        color: theme.color.pink,
        cutout: "50%",
      },

    ]    
    let commutingData = [
      {
        label: "Car, truck or van",
        value: 76.0,
        color: theme.color.midBlue,
        cutout: "50%",
      },
      {
        label: "Public Transit",
        value: 15.6,
        color: theme.color.blue,
        cutout: "50%",
      },
      {
        label: "Walked",
        value: 4.6,
        color: theme.color.turquoise,
        cutout: "50%",
      },
      {
        label: "Bicycle",
        value: 1.0,
        color: theme.color.pink,
        cutout: "50%",
      },
      {
        label: "Other",
        value: 2.7,
        color: theme.color.grey5,
        cutout: "50%",
      },


    ]    

    setEducationGraphData({
        labels: educationData.map((item) => item.label),
        datasets: [
          {
            data: educationData.map((item) => Math.round(item.value)),
            backgroundColor: educationData.map((item) => item.color),
            borderColor: educationData.map((item) => item.color),
            borderWidth: 1,
            dataVisibility: new Array(educationData.length).fill(true),
          },
        ],
      })
      setCommutingGraphData({
        labels: commutingData.map((item) => item.label),
        datasets: [
          {
            data: commutingData.map((item) => Math.round(item.value)),
            backgroundColor: commutingData.map((item) => item.color),
            borderColor: commutingData.map((item) => item.color),
            borderWidth: 1,
            dataVisibility: new Array(commutingData.length).fill(true),
          },
        ],
      })
 

  }, [])
  
  useEffect(() => {
    if (!locationsList) {
      return
    }

    if (locationsList[0]) {
      setLocation(locationsList[0])
    }
  }, [locationsList])

  // Check default dates
  useEffect(() => {
    if (endDate) {
      return
    }
    if (location && localStorage.getItem('token')) {
      try {
        GetDistanceTravelledDefaultEndDate(location)
          .then(defaultEndDate => {
            if (defaultEndDate.year && defaultEndDate.month) {
              setEndDate(dayjs().year(defaultEndDate?.year).month(defaultEndDate?.month - 1).endOf('month'))
            } else {
              // window.location.reload()
              console.error('Error: defaultEndDate data empty')
            }
          })
      } catch (error) {
        console.error('Error fetching data:', error)
      }
    }
  }, [location])

  // Fetch data
  useEffect(() => {
    if (!endDate) {
      return
    }
    if (location && location.latitude !== undefined && location.longitude !== undefined) {
      const fetchData = async () => {
        try {
          await GetDistanceTravelledMetrics(endDate, location).then(data => {
            setReportData(data)
          })

          await GetDistanceTravelledPersona().then(data => {
            setReportData(prevState => ({ ...prevState, personas: data }))
          })
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      }

      fetchData()
    }
  }, [location, endDate])

  useEffect(() => {
    if (reportData?.city) {
      // TODO: Update date picker to show default dates
      if (userData.distance_unit === 'mi') {
        setSummaryData({
          average: reportData.city.mean / KM_TO_MILES,
          median: reportData.city.median / KM_TO_MILES,
          topCity: reportData.city.topRegion,
          topPostalCode: reportData.postalCode?.topRegion ? reportData.postalCode?.topRegion : null,
        })
      } else {
        setSummaryData({
          average: reportData.city.mean,
          median: reportData.city.median,
          topCity: reportData.city.topRegion,
          topPostalCode: reportData.postalCode?.topRegion ? reportData.postalCode?.topRegion : null,
        })
      }
      // Set summary data
      // Set bar graph labels and data
      const cityBarGraphXLabels = reportData.city.topTenData
        .map(item => {
          return item.name
        })
        .reverse()
      const cityBarGraphMetrics = reportData.city.topTenData
        .map(item => {
          // return userData.distance_unit === 'mi'
          //   ? Math.round((item.value * 100) / KM_TO_MILES) / 100
          //   : item.value
          return item.value
        })
        .reverse()
      setCityBarGraphData({
        labels: cityBarGraphXLabels,
        datasets: [
          {
            data: cityBarGraphMetrics,
            backgroundColor: ['#1d4880'],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })
    } else {
      setSummaryData({
        average: 0,
        median: 0,
        topCity: 'N/A',
        topPostalCode: 'N/A',
      })
      setCityBarGraphData({
        labels: [],
        datasets: [
          {
            data: null,
            backgroundColor: ['#1d4880'],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })
    }

    if (reportData?.postalCode) {
      // TODO: Update date picker to show default dates

      // Set summary data
      if (userData.distance_unit === 'mi') {
        setSummaryData({
          average: reportData.city.mean / KM_TO_MILES,
          median: reportData.city.median / KM_TO_MILES,
          topCity: reportData.city.topRegion,
          topPostalCode: reportData.postalCode?.topRegion ? reportData.postalCode?.topRegion : null,
        })
      } else {
        setSummaryData({
          average: reportData.city.mean,
          median: reportData.city.median,
          topCity: reportData.city.topRegion,
          topPostalCode: reportData.postalCode?.topRegion ? reportData.postalCode?.topRegion : null,
        })
      }

      const postalCodeBarGraphXLabels = reportData.postalCode.topTenData
        .map(item => {
          return item.name
        })
        .reverse()
      const postalCodeBarGraphMetrics = reportData.postalCode.topTenData
        .map(item => {
          // return userData.distance_unit === 'mi'
          //   ? Math.round((item.value * 100) / KM_TO_MILES) / 100
          //   : item.value
          return item.value
        })
        .reverse()

      setPostalCodeBarGraphData({
        labels: postalCodeBarGraphXLabels,
        datasets: [
          {
            data: postalCodeBarGraphMetrics,
            backgroundColor: ['#1d4880'],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })
    } else {
      setSummaryData({
        average: 0,
        median: 0,
        topCity: 'N/A',
        topPostalCode: 'N/A',
      })
      setPostalCodeBarGraphData({
        labels: [],
        datasets: [
          {
            data: null,
            backgroundColor: ['#1d4880'],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })
    }

    if (reportData?.personas) {
      // Create chips for persona selector
      const personasObject = Object.keys(reportData?.personas).map(persona => ({
        chipLabel: persona.replace(/_/g, ' ').replace(/\b\w/g, c => c.toUpperCase()),
        chipValue: persona,
      }))
      setAllPersonas(personasObject)
    } else {
      setAllPersonas([])
    }
  }, [reportData])

  useEffect(() => {
    if (reportData?.personas) {
      const snakeCaseToTitleCase = string => {
        return string
          .split(' ')
          .filter(x => x.length > 0)
          .map(x => x.charAt(0).toLowerCase() + x.slice(1))
          .join('_')
      }

      const personaAverageGraphMetrics = appState.selectedPersonas.map(persona => {
        const personaChip = snakeCaseToTitleCase(persona)
        return reportData.personas[personaChip].mean
      })

      setPersonaAverageBarGraphData({
        labels: appState.selectedPersonas,
        datasets: [
          {
            data: personaAverageGraphMetrics,
            backgroundColor: [
              'rgba(255, 99, 132, 0.7)',
              'rgba(54, 162, 235, 0.7)',
              'rgba(255, 206, 86, 0.7)',
              'rgba(75, 192, 192, 0.7)',
            ],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })

      const personaMedianGraphMetrics = appState.selectedPersonas.map(persona => {
        const personaChip = snakeCaseToTitleCase(persona)
        return reportData.personas[personaChip].median
      })

      setPersonaMedianBarGraphData({
        labels: appState.selectedPersonas,
        datasets: [
          {
            data: personaMedianGraphMetrics,
            backgroundColor: [
              'rgba(255, 99, 132, 0.7)',
              'rgba(54, 162, 235, 0.7)',
              'rgba(255, 206, 86, 0.7)',
              'rgba(75, 192, 192, 0.7)',
            ],
            borderColor: [],
            // borderWidth: 1
          },
        ],
      })

      const personaTopCities = appState.selectedPersonas.map(persona => {
        const personaChip = snakeCaseToTitleCase(persona)
        return reportData.personas[personaChip].topCity
      })

      setPersonaTopCityData(
        appState.selectedPersonas.map((persona, index) => {
          return {
            label: persona,
            value: personaTopCities[index],
          }
        }),
      )

      const personaTopPostalCodes = appState.selectedPersonas.map(persona => {
        const personaChip = snakeCaseToTitleCase(persona)
        return reportData.personas[personaChip].topPostalCode
      })

      setPersonaTopPostalCodeData(
        appState.selectedPersonas.map((persona, index) => {
          return {
            label: persona,
            value: personaTopPostalCodes[index],
          }
        }),
      )
    }
  }, [location, endDate, appState.selectedPersonas])

  // Handlers
  const handleDateChange = newDate => {
    setEndDate(newDate)
  }

  const cityBarGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  const ageBarGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  const postalCodeBarGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,

        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,

    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
  }

  const personaBarGraphOptions = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: false,
      },
      title: {
        display: true,
        position: 'top',
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    // barThickness: 30,
    scales: {
      y: {
        ticks: {
          beginAtZero: true,
          stepSize: 10,
        },
      },
    },
    indexAxis: 'y',
  }

  // props for map

  const mapDefaultProps = {
    center: {
      lat: 37.7749,
      lng: -122.4194,
    },
    zoom: 11,
  }

  // styles
  const valueTooltipContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  const mapContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  const graphContainerStyle = {
    minWidth: '200px',
    margin: '.5rem',
  }

  let mapDate = { day: endDate?.date(), month: endDate?.month() + 1, year: endDate?.year() }

  let mapLocation = {
    id: location?.id,
    name: location?.name,
    sensorData: null,
    address: {
      lines: [location?.address],
      city: location?.city,
      region: location?.state,
      country: location?.country,
      postalCode: location?.zipcode,
    },
    center: {
      latitude: location?.latitude,
      longitude: location?.longitude,
    },
    type: 'address',
  }
  const usRegionCity = { label: 'City', value: 'adm2-US' }
  const usRegionPostal = { label: 'Postal Code', value: 'pos4-US' }
  const caRegionCity = { label: 'City', value: 'sta2-CA' }
  const caRegionPostal = { label: 'Postal Code', value: 'pos3-CA' }

  return (
    <PageContainer>
      <div id="pdf-content" style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        <Box
          container
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
            marginTop: '32px',
            marginBottom: '32px',
          }}
        >
          <DashboardToolbar
            page="distance-travelled"
            endDate={endDate}
            onDateChange={handleDateChange}
          />
        </Box>

        <Box
          container
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <MetricCard
            title="Average Distance Travelled"
            metric={
              summaryData?.average
                ? `${Math.round(summaryData.average * 100) / 100} ${
                    userData.distance_unit === 'mi' ? 'mi' : 'km'
                  }`
                : 'N/A'
            }
            helpText="The average distance visitor households are from the location."
          />
          <MetricCard
            title="Median Distance Travelled"
            metric={
              summaryData?.median
                ? `${Math.round(summaryData.median * 100) / 100} ${
                    userData.distance_unit === 'mi' ? 'mi' : 'km'
                  }`
                : 'N/A'
            }
            helpText="The median distance visitor households are from the location. This can be useful in eliminating outliers that inflate the overall average."
          />
          <MetricCard
            title="Top City"
            metric={summaryData?.topCity ? summaryData?.topCity : 'N/A'}
            helpText="The city most visitors come from."
          />
          <MetricCard
            title={location?.country === 'US' ? 'Top Zip Code Region' : 'Top Postal Code Region'}
            metric={summaryData?.topPostalCode ? summaryData?.topPostalCode : 'N/A'}
            helpText="The three digit postal code region most visitors come from."
          />
        </Box>

        <Grid
          container
          mt="16px"
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <Grid item md={12}>
            <Typography
              variant="h3"
              mt="32px"
              mb="8px"
              color={theme.color.black}
              sx={{
                fontSize: '28px',
              }}
            >
              Distance Travelled by City
            </Typography>
          </Grid>
          <Grid item md={6}>
            {location && endDate && (
              <CustomCard
                height="455px"
                body={
                  <DistanceTravelledMap
                    date={mapDate}
                    regionValue={location?.country === 'US' ? usRegionCity : caRegionCity}
                    location={mapLocation}
                    personas={personas}
                  />
                }
              />
            )}
          </Grid>

          <Grid item md={6}>
            {location && (
              <CustomCard
                body={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      borderRadius: '12px',
                      padding: '16px',
                    }}
                  >
                    {' '}
                    <HelpTooltip
                      sx={{
                        position: 'absolute',
                        right: '10',
                        top: '10',
                      }}
                      tooltip={'Visit % by city'}
                    />
                    <Box
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h2">Visit % by City</Typography>
                      <DataCard>
                        {cityBarGraphData && (
                          <ModularBarGraph
                            data={cityBarGraphData}
                            options={cityBarGraphOptions}
                            height="400"
                            width="400"
                          />
                        )}
                      </DataCard>
                    </Box>
                  </Box>
                }
              />
            )}
          </Grid>
          <Grid item md={12} mt="32px">
            <Typography
              variant="h3"
              mt="32px"
              mb="8px"
              color={theme.color.black}
              sx={{
                fontSize: '28px',
              }}
            >
              {location?.country == 'US'
                ? 'Distance Travelled by Zip Code'
                : 'Distance Travelled by Postal Code'}
            </Typography>
          </Grid>
          <Grid item md={6}>
            {location && endDate && (
              <CustomCard
                height="455px"
                body={
                  <DistanceTravelledMap
                    date={mapDate}
                    regionValue={location?.country === 'US' ? usRegionPostal : caRegionPostal}
                    location={mapLocation}
                    personas={personas}
                  />
                }
              />
            )}
          </Grid>

          <Grid item md={6}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  {' '}
                  <HelpTooltip tooltip={'Visit % by postal code'} />
                  <Box
                    style={{
                      textAlign: 'center',
                      display: 'flex',
                      flexGrow: 1,
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant="h2">
                      {location?.country === 'US'
                        ? 'Visit % by Zip Code'
                        : 'Visit % by Postal Code'}
                    </Typography>
                    <DataCard>
                      {postalCodeBarGraphData && (
                        <ModularBarGraph
                          data={postalCodeBarGraphData}
                          options={postalCodeBarGraphOptions}
                          height="400"
                          width="400"
                        />
                      )}
                    </DataCard>
                  </Box>
                </Box>
              }
            />
          </Grid>
        </Grid>

        {showCensus && (
        <Grid
          container
          mt="16px"
          sx={{
            paddingLeft: '32px',
            paddingRight: '32px',
          }}
        >
          <Grid item md={12}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <Grid
                    container
                    rowSpacing={'16px'}

                  >
                    <Grid item md={12}
                      sx={{
                        marginBottom: '12px',
                      }}
                    >

                      <Typography variant="h2" textAlign={'center'}>
                        Household Data
                      </Typography>
                    </Grid>
                    
                      <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Household Size
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={6}>
                            <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <PersonIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 25.0%</Typography>

                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > 1 Person</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <PeopleIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 28.5%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > 2 People</Typography>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <GroupsIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 17.3%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > 3 People</Typography>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <PersonAddIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.midBlue,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 29.3%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > 4 or More</Typography>
                              </Box>
                            </Box>

                          </Grid>

                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                      <Typography variant="h2"
                        sx={{
                          fontSize: 18,
                        }}
                      >
                        Age
                      </Typography>
                      <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={12}>
                          {ageBarGraphData &&
                            <ModularBarGraph
                              data={ageBarGraphData}
                              options={ageBarGraphOptions}
                              height="300"
                              width="400"
                            />
                          }
                          </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Dwelling Type
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={6}>
                            <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <HomeIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 39.0%</Typography>

                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Detached </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <HomeWorkIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 16.6%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Attached</Typography>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <ApartmentIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.midBlue,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 44.2%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Multi-unit</Typography>
                              </Box>
                            </Box>

                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <AirportShuttleIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 0.0%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                >Other</Typography>
                              </Box>
                            </Box>

                          </Grid>

                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Household Type
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={6}>
                            <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <HomeIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.midBlue,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 70.2%</Typography>

                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Family Household </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                            <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <HomeIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.grey5,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 29.8%</Typography>

                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Non-Family Household </Typography>
                              </Box>
                            </Box>
                          </Grid>

                        </Grid>
                      </Grid>
                  </Grid>
                </Box>
              }
            />
          </Grid>
          <Grid item md={12}
            sx={{
              marginTop: '16px',
            }}
          >
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <Grid
                    container
                    rowSpacing={'16px'}

                  >
                    <Grid item md={12}
                      sx={{
                        marginBottom: '12px',
                      }}
                    >

                      <Typography variant="h2" textAlign={'center'}>
                        Marriage & Ethnicity
                      </Typography>
                    </Grid>
                    
                      <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Marital Status
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Married
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    48.89%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Living common law - Never married
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    4.66%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Living common law - Separated
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    0.25%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Living common law - Divorced	
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    1.19%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Living common law - Widowed
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    0.11%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Not married and not living common-law
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    44.90%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Official Languages
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    English
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    88.24%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    French
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    0.07%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    English & French
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    7.41%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Neither English or French
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    4.28%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                            </TableBody>
                          </Table>
                        </Grid>
                    </Grid>
                  
                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Race
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Not a visible minority
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    43.00%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    South Asian
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    19.25%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Chinese
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    11.07%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Black
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    7.95%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Filipino
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    4.58%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Arab
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    2.11%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Latin American
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    2.55%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Southeast Asian
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    1.67%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    West Asian
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    2.61%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Korean
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    1.21%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Japanese
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    0.33%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Other
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    1.55%
                                  </Typography>
                                </TableCell>
                              </TableRow>

                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Multiple Visible Minorities
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    2.14%
                                  </Typography>
                                </TableCell>
                              </TableRow>


                            </TableBody>
                          </Table>
                        </Grid>
                    </Grid>
                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Citizenship
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Table>
                            <TableBody>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Non-immigrants
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    49.33%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Non-permanent residents
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    4.07%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      color: theme.color.grey6,
                                    }}
                                  >
                                    Immigrants
                                  </Typography>
                                   
                                </TableCell>
                                <TableCell align="right">
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 700,
                                      color: theme.color.midBlue,
                                    }}
                                  >
                                    46.60%
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Grid>
                    </Grid>
                 </Grid>
                </Box>
              }
            />
          </Grid>
          <Grid item md={12} sx={{marginTop: '16px'}}>
            <CustomCard
              body={
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                    borderRadius: '12px',
                    padding: '16px',
                  }}
                >
                  <Grid
                    container
                    rowSpacing={'16px'}

                  >
                    <Grid item md={12}
                      sx={{
                        marginBottom: '12px',
                      }}
                    >

                      <Typography variant="h2" textAlign={'center'}>
                        Labour & Education
                      </Typography>
                    </Grid>
                    
                      <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Key Metrics
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={6}>
                            <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <AttachMoneyIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.midBlue,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > $69,500</Typography>

                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > Median Full-time Income</Typography>
                              </Box>
                            </Box>
                          </Grid>
                          <Grid item md={6}>
                          <Box sx={{
                              display: 'flex',
                              flex: 1,
                              flexDirection: 'row',
                              padding: '4px',
                              alignItems: 'center',
                              justifyContent: 'center'
                            }}>
                              <WorkIcon sx={{
                                fontSize: 64,
                                marginRight: '8px',
                                color: theme.color.midBlue,
                              }}/>
                              <Box>
                                <Typography
                                  sx={{
                                    color: theme.color.midBlue,
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginBottom: 0,
                                    paddingBottom: 0,
                                  }}
                                > 64.64%
                                </Typography>
                                <Typography
                                  sx={{
                                    paddingTop: 0,
                                    marginTop: 0,
                                    fontSize: 14,
                                    color: theme.color.grey5,
                                  }}
                                > In the Labour Force (15+)</Typography>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                      <Typography variant="h2"
                        sx={{
                          fontSize: 18,
                        }}
                      >
                        Income
                      </Typography>
                      <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={12}>
                          {incomeBarGraphData &&
                            <ModularBarGraph
                              data={incomeBarGraphData}
                              options={ageBarGraphOptions}
                              height="300"
                              width="400"
                            />
                          }
                          </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Education
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={12}
                            sx={{
                              maxHeight: '400px',                              
                            }}
                          >
                          {educationGraphData &&
                            <Doughnut
                              data={educationGraphData}
                              options={
                                {
                                  plugins: {
                                    responsive: true,
                                    datalabels: {
                                      formatter: function (value) {
                                        return value.toString() +'%'; //for number format
                                      },
                                      color: "red",
                                      
                                      font: {
                                        weight: 'bold',
                                        size:14,
                                        family: 'poppins',
                                      },
                                    },                                
  
                                  },
                              }
                              }
                            />
                          }
                          </Grid>
                        </Grid>
                    </Grid>
                    <Grid item md={6} padding={'8px'}>
                        <Typography variant="h2"
                          sx={{
                            fontSize: 18,
                          }}
                        >
                          Commuting (Primary Means)
                        </Typography>
                        <hr color={theme.color.grey2} />
                        <Grid container>
                          <Grid item md={12}
                            sx={{
                              maxHeight: '400px',                              
                            }}
                          >
                          {commutingGraphData &&
                            <Doughnut
                              data={commutingGraphData}
                              options={
                                {
                                  plugins: {
                                    responsive: true,
                                    datalabels: {
                                      formatter: function (value) {
                                        return value.toString() +'%'; //for number format
                                      },
                                      color: "red",
                                      
                                      font: {
                                        weight: 'bold',
                                        size:14,
                                        family: 'poppins',
                                      },
                                    },                                
  
                                  },
                              }
                              }
                            />
                          }
                          </Grid>
                        </Grid>
                    </Grid>
                  </Grid>
                </Box>
              }
            />
          </Grid>

        </Grid>
        )}
        <Box
          sx={{
            display: 'block',
            marginTop: '64px',
            backgroundImage: "url('images/header-fade@2x.png')",
            backgroundRepeat: 'repeat-x',
            backgroundPosition: 'center top',
            width: '100%',
          }}
        >
          {/* <Grid container padding="32px" mb="32px" mt="16px" rowSpacing={'16px'}>
            <Grid item md={8}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Groups
                  fontSize="50px"
                  marginRight="8px"
                  sx={{
                    fontSize: '55px',
                    color: theme.color.white,
                    marginRight: '12px',
                  }}
                />
                <Typography
                  variant="h1"
                  sx={{
                    color: theme.color.white,
                    fontSize: '50px',
                  }}
                >
                  Persona Analysis
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{
                  color: theme.color.white,
                  fontSize: '18px',
                  marginTop: '8px',
                }}
              >
                Gain insights by understanding how your customers behave. <br /> Personas give you a
                sense of what market segments visit you, and the differences between them.
              </Typography>
            </Grid>
            <Grid item md={4}>
              <PersonaSelector />
            </Grid>
            <Grid item md={12}>
              <CustomCard
                backgroundColor="rgba(255, 255, 255, 0.9)"
                body={
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                      borderRadius: '12px',
                      padding: '16px',
                    }}
                  >
                    <HelpTooltip
                      sx={{
                        position: 'absolute',
                        right: '16px',
                        top: '16px',
                      }}
                      tooltip={
                        'The average dwell time per visit (in minutes) for each day of the specified week.'
                      }
                    />
                    <Box
                      style={{
                        textAlign: 'center',
                        display: 'flex',
                        flexGrow: 1,
                        flexDirection: 'column',
                      }}
                    >
                      <Typography variant="h2">Placeholder</Typography>
                      <DataCard></DataCard>
                    </Box>
                  </Box>
                }
              />
            </Grid>
          </Grid> */}
        </Box>
      </div>
    </PageContainer>
  )
}

export default DistanceTravelledPage
